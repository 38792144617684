import { KEY_ENTER, KEY_ESC } from '/machinery/keys'
import { Icon } from '/includes/icon/Icon'
import { useViewport } from '/machinery/Viewport'
import styles from './SearchToggle.css'

export function SearchToggle({ layoutClassName, action, labelSearch, labelClose, iconSearch, iconClose, query: initialQuery }) {
  const [isOpen, setIsOpen] = React.useState(true)
  const [query, setQuery] = React.useState(initialQuery)
  const inputRef = React.createRef(null)
  const { viewportLg } = useViewport()

  React.useEffect(() => {
    if (viewportLg) {
      setIsOpen(false)
    }
  }, [viewportLg])

  React.useEffect(
    () => {
      if (!isOpen) return

      document.addEventListener('keydown', handleKeyDown)

      return () => { document.removeEventListener('keydown', handleKeyDown) }

      function handleKeyDown(event) {

        if (event.keyCode === KEY_ESC) {
          event.preventDefault()
          setIsOpen(false)
          inputRef.current.blur()
        }

        if (event.keyCode === KEY_ENTER) {
          setIsOpen(false)
          inputRef.current.blur()
          window.location.href = `/?${new URLSearchParams({ s: inputRef.current.value })}`
          event.preventDefault()
        }
      }
    },
    [isOpen, inputRef]
  )

  return (
    <div className={cx(styles.component, layoutClassName, styles.relativeToParent, isOpen && styles.isVisible)}>
      {viewportLg &&
        <button
          aria-expanded={isOpen}
          className={styles.toggleButton}
          type="button"
          onClick={_ => {
            setIsOpen(prevValue => {
              if (!prevValue) inputRef.current.focus()
              return !prevValue
            })
          }

          }
          data-togglepane="search"
        >
          <span className={cx(styles.icon, styles.iconOpen, !isOpen && styles.isVisible)}>
            <Icon label={labelSearch} icon={iconSearch} />
          </span>
          <span className={cx(styles.icon, styles.iconClose, isOpen && styles.isVisible)}>
            <Icon label={labelClose} icon={iconClose} />
          </span>
        </button>
      }
      <div className={cx(styles.formContainer, isOpen && styles.isVisible)}>
        <form className={styles.form} role="search" method="get" {...{ action }}>
          <input
            className={styles.input}
            type="text"
            placeholder='Zoeken'
            name="s"
            value={query}
            ref={inputRef}
            onChange={e => setQuery(e.target.value)}
            tabIndex={isOpen ? '0' : '-1'}
            aria-label="search"
          />
          <button className={styles.submitButton} type="submit" tabIndex={isOpen ? '0' : '-1'} data-name="submit-search">
            <Icon label={labelSearch} icon={iconSearch} />
          </button>
        </form>
      </div>
    </div>
  )
}
