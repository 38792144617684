import { ViewportContextProvider } from '/machinery/Viewport'
import { usePhpProps } from '/machinery/usePhpProps'
import { useI18n, I18nContextProvider } from '/machinery/I18n'
import { BREAKPOINTS } from '/config'
import { SearchToggle } from './SearchToggle'

import styles from './SearchApp.css'

import iconSearch from '/images/icon-search.raw.svg'
import iconClose from '/images/icon-close.raw.svg'

export default function SearchApp({ phpProps }) {
  const { action, i18n, query } = usePhpProps(phpProps, { action: '', i18n: {}, query: '' })

  return (
    <I18nContextProvider value={i18n}>
      <ViewportContextProvider breakpoints={BREAKPOINTS}>
        <Search {...{ action, query }} />
      </ViewportContextProvider>
    </I18nContextProvider>
  )
}

function Search({ action, query }) {
  const i18n = useI18n()
  return (
    <div className={cx(styles.appSearch, styles.relativeToParent)}>
      <SearchToggle
        layoutClassName={cx(styles.toggle, styles.relativeToParent)}
        labelSearch={i18n('zoeken')}
        labelClose={i18n('sluiten')}
        {...{ action, iconClose, iconSearch, query }}
      />
    </div>
  )
}
