import mediaStyles from '/cssGlobal/mq.css'

const breakpoints = {
  xs: parseInt(mediaStyles['breakPointXs'], 10),
  sm: parseInt(mediaStyles['breakPointSm'], 10),
  md: parseInt(mediaStyles['breakPointMd'], 10),
  lg: parseInt(mediaStyles['breakPointLg'], 10),
  xl: parseInt(mediaStyles['breakPointXl'], 10),
  xxl: parseInt(mediaStyles['breakPointXxl'], 10)
}

export const BREAKPOINTS = [
  { name: 'XXS', minWidth: 0 },
  { name: 'XS', minWidth: breakpoints.xs },
  { name: 'SM', minWidth: breakpoints.sm },
  { name: 'MD', minWidth: breakpoints.md },
  { name: 'LG', minWidth: breakpoints.lg },
  { name: 'XL', minWidth: breakpoints.xl },
  { name: 'XXL', minWidth: breakpoints.xxl },
]
