const noContextProvider = Symbol('noContextProvider')
const I18nContext = React.createContext(noContextProvider)

export function useI18n() {
  const config = React.useContext(I18nContext)
  if (config === noContextProvider)
    throw new Error('Please make sure I18nContextProvider is available')

  return key => (config && config[key]) || ''
}

export function I18nContextProvider({ children, value }) {
  return <I18nContext.Provider {...{ value }}>{children}</I18nContext.Provider>
}
