import styles from './Icon.css'

export function Icon({ label, icon, layoutClassName = undefined }) {
  return (
    <span
      className={cx(styles.component, layoutClassName)}
      role="img"
      aria-label={label}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}
